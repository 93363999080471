/*
font-family:'Helvetica Neue LT W05_45 Light';
font-family:'Helvetica Neue LT W05_46 Lt It';
font-family:'Helvetica Neue LT W05_55 Roman';
font-family:'Helvetica Neue LT W05_56 Italic';
font-family:'Helvetica Neue LT W05_65 Medium';
font-family:'Helvetica Neue LT W05_66 Md It';
font-family:'Helvetica Neue LT W01_75 Bold';
font-family:'Helvetica Neue LT W05_76 Bd It';
 */


/*
body{
	font-family:'Helvetica Neue LT W05_55 Roman';
	font-size: 18px;
	line-height: 22px;
	color: $text-color;
}

h2,h3,h4,h5,h6{
	font-family:'Helvetica Neue LT W01_75 Bold';
}

.bolder{
	font-family:'Helvetica Neue LT W01_75 Bold';
}

.lighter{
	font-family:'Helvetica Neue LT W05_45 Light';
}*/


body{
	font-family:'Helvetica', sans-serif;
	font-size: 16px;
	line-height: 26px;
	color: $text-color;
}


h1, h2, h3, h4, h5, .sppb-btn, .btn{
	font-family: "Montserrat", "Helvetica", Arial, sans-serif !important;
}