.mod_charity {

  &.vertical {
    background: #ffffff;
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, #cbdae9 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #ffffff), color-stop(100%, #cbdae9));
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%, #cbdae9 100%);
    background: -o-radial-gradient(center, ellipse cover, #ffffff 0%, #cbdae9 100%);
    background: -ms-radial-gradient(center, ellipse cover, #ffffff 0%, #cbdae9 100%);
    background: radial-gradient(ellipse at center, #ffffff 0%, #cbdae9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cbdae9',GradientType=1 );
    .total_amount {
      padding: 20px;
      background-color: #FFF;
    }
  }

	.image{
		background-color: white;
	}

  .total_amount {
    h3 {
      font-size: 40px;
      text-align: center;
    }
    p {
      text-align: center;
      text-transform: uppercase;
      padding: 0px;
      color: #999;
      margin: 0px;
    }
  }
  .ticker_projects {
    border-color: var(--primary-color) !important;
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      li {
        padding: 20px;
        text-align: center;
      }
    }
    span {
      display: block;
    }
    .title {
      font-weight: bold;
    }
    .onlus {
      font-size: 90%;
    }
    .amount {
      font-size: 160%;
      font-weight: bold;
      padding: 8px 0px;
    }
  }


  /*
  &.horizontal {
    border-top: 4px solid #ff6e25;
    margin-top: 0;
    margin-bottom: 0;
    .total_amount {
      margin-top: 20px;
    }
    .ticker_projects {
      border-top: none;
    }
    .total_amount h3 {
      color: #0A509D;
    }
    .ticker_projects .amount {
      float: none;
    }
    .btn {
      background-color: #ff6e25;
      border-color: #ff6e25;
      color: #ffffff;
    }
    .ticker_projects ul {
      width: 100%;
      text-align: center;
    }
  }
  */
}